<template>
  <div>
    <b-card>
      <AgentProfileForm :agent-id="agentId"/>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AgentForms',
  components: {
    AgentProfileForm: () =>
      import('@components/agents/agent-profile-form.vue'),
  },
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isFetchingAgent: (state) => state.agent.isFetchingAgent,
    }),
  },
  methods: {
    async onSubmitResetPassword() {
      this.$refs.agentResetPassword.onClose()
    },
  },
}
</script>
